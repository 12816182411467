import React from "react";
import { graphql, Link } from "gatsby";
import { css } from "@emotion/core";

import Layout from "../components/layout";

const TheoriesPage = ({ data }) => (
  <Layout title="theories">
    <section>
      {data.theories.nodes.map((theory) => (
        <div
          key={theory.id}
          css={css`
            margin-bottom: 20px;
          `}
        >
          <Link to={`/theories/${theory.slug.current}`}>
            <p
              css={css`
                display: inline;
                color: #7b68ee;
                margin-right: 20px;
              `}
            >
              {theory.date}
            </p>
            <h3
              css={css`
                display: inline;
              `}
            >
              {theory.title}
            </h3>
          </Link>
        </div>
      ))}
    </section>
  </Layout>
);

export const query = graphql`
  query TheoriesPageQuery {
    theories: allSanityTheory {
      nodes {
        title
        date
        slug {
          current
        }
        id
      }
    }
  }
`;

export default TheoriesPage;
